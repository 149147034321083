import validateBase from './validate'

export type KeysOfUnion<T> = T extends T ? keyof T : never

export type ArrayElement<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer E> ? E : never

export type PartialPick<T, K extends keyof T> = {
  [_ in K]?: T[K]
}

export type Maybe<T, Prop extends PropertyKey, F = T> = T extends { [_ in Prop]?: infer R } ? R | undefined : F

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type NotUndefined = {} | null

export type PickRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K]
}

export type OverrideFirstParameter<F, T> =
  F extends (first: infer A, ...args: infer P) => infer R ? (first: Omit<A, keyof T> & T, ...args: P) => R : never

export const validate = <T extends object | undefined>(key: string, data: T): T => {
  if (import.meta.env.VITE_ENABLE_RESPONSE_VALIDATION) {
    void validateBase(key, data)
  }

  return data
}

export const isValidId = (id: string) => /^ext-|^[A-Z][A-Z0-9]-[A-Z0-9]+-[A-Z0-9]+|^\d+$/.test(id)
