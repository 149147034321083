import { R, S } from 'core/helpers'

if (import.meta.env.VITE_ENABLE_DEV_HELPERS) {
  const collectNodeIterator = (root: Node, whatToShow?: number, filter?: NodeFilter | null) => {
    const iterator = document.createNodeIterator(root, whatToShow, filter)
    const list: Array<Node> = []
    let node: Node | null

    while ((node = iterator.nextNode())) {
      list.push(node)
    }

    return list
  }

  const getRoundedClientRect = (element: Element | Range) => {
    const r = element.getBoundingClientRect()
    r.x = Math.round(r.x)
    r.y = Math.round(r.y)
    r.width = Math.round(r.width)
    r.height = Math.round(r.height)

    return r
  }

  const adjacent = (r1: DOMRect, r2: DOMRect) =>
    r1.y === r2.y && r1.height === r2.height && r1.right >= r2.x && r1.x <= r2.right

  const within = (r1: DOMRect, r2: DOMRect) =>
    r1.x >= r2.x && r1.y >= r2.y && r1.right <= r2.right && r1.bottom <= r2.bottom

  // @ts-ignore
  window.makeSkeleton = (root: HTMLElement) => {
    const rect = getRoundedClientRect(root)
    rect.width = Math.round(rect.width)
    rect.height = Math.round(rect.height)

    const list = R.pipe(
      collectNodeIterator(root, NodeFilter.SHOW_TEXT),
      R.map((node) => {
        const range = document.createRange()
        range.selectNode(node)

        return getRoundedClientRect(range)
      }),
      R.concat(
        R.map(
          collectNodeIterator(root, NodeFilter.SHOW_ELEMENT, {
            acceptNode: (node: Element) =>
              node.className.includes('BaseSelect__Box') || !node.childNodes.length ?
                NodeFilter.FILTER_ACCEPT
              : NodeFilter.FILTER_REJECT,
          }),
          (node) => getRoundedClientRect(node as Element),
        ),
      ),
      R.filter((r) => r.width > 0 && r.height > 0),
      // Merge all adjacent or completely contained rects
      R.reduce((acc, r1) => {
        const [removed, remaining] = R.partition(
          acc,
          (r2) => adjacent(r1, r2) || adjacent(r2, r1) || within(r1, r2) || within(r2, r1),
        )

        const intersection = [...removed, r1]
        const x = R.firstBy(intersection, R.prop('x'))?.x ?? 0
        const y = R.firstBy(intersection, R.prop('y'))?.y ?? 0
        const right = R.firstBy(intersection, [R.prop('right'), 'desc'])?.right ?? 0
        const bottom = R.firstBy(intersection, [R.prop('bottom'), 'desc'])?.bottom ?? 0

        return [...remaining, new DOMRect(x, y, right - x, bottom - y)]
      }, [] as Array<DOMRect>),
      R.map(
        ({ height, width, x, y }) => `<rect height="${height}" width="${width}" x="${x - rect.x}" y="${y - rect.y}" />`,
      ),
      R.join('\n  '),
    )

    const margin = getComputedStyle(root).margin
    const attr = [`height="${rect.height}px"`, margin !== '0px' && `margin="${margin}"`, `width="${rect.width}px"`]
    const value = `<Skeleton ${S.join(attr, ' ')}>\n  ${list}\n</Skeleton>`
    void navigator.clipboard.writeText(value)

    return value
  }
}
