import type Ajv from 'ajv'

let ajv: Ajv

const validate = async (key: string, data: unknown) => {
  const [{ default: Ajv, _: ajvTemplate }, { default: addFormats }, schemas] = await Promise.all([
    import('ajv/dist/2019'),
    import('ajv-formats'),
    import('./generated/schemas.json'),
  ])

  if (ajv === undefined) {
    ajv = new Ajv({
      schemas,
      inlineRefs: false,
      code: {
        source: true,
        esm: true,
        lines: true,
        // Work around for ajv-validator/ajv#2477
        // @ts-ignore
        regExp: (pattern, u) => {
          try {
            return new RegExp(pattern, u)
          } catch (e) {
            console.error('Bad RegExp: ', pattern, e)
          }
        },
      },
      allowUnionTypes: true,
      allErrors: true,
      verbose: true,
    })
      .addKeyword({
        keyword: 'typeof',
        schemaType: 'string',
        code: (cxt) => cxt.pass(ajvTemplate`typeof ${cxt.data} === ${cxt.schema}`),
      })
      .addKeyword({
        keyword: 'instanceof',
        schemaType: 'string',
        code: (cxt) => cxt.pass(ajvTemplate`${cxt.data} instanceof ${cxt.schema}`),
      })

    addFormats(ajv)
  }

  const validate = ajv?.getSchema(key)

  if (validate && !validate(data)) {
    // eslint-disable-next-line no-console
    console.warn(
      `Validation failed for response body of ${key.substring(key.lastIndexOf('/') + 1)}`,
      validate.errors?.length === 1 ? validate.errors[0] : validate.errors,
      data,
      validate.schema,
    )
  }
}

export default validate
